<script>
  import {
    GridContainer,
    GridColumn,
    Icon,
    Separator,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import Item from './Item'
  import classNames from 'classnames/bind'

  import css from './style.scss'

  const cN = classNames.bind(css)

  export let config

  const {
    title,
    titleTag,
    subTitle: subTitleTpl,
    avgRank,
    maxRank,
    totalRanksAmount,
    list = [],
    withTopMargin,
    withBottomMargin,
    columns = 3,
  } = config

  $: subTitle =
    subTitleTpl !== undefined
      ? subTitleTpl
          .replace('$avg', avgRank)
          .replace('$max', maxRank)
          .replace('$amount', totalRanksAmount.toLocaleString('ru'))
      : undefined

  const perColumn = list.length / columns
  const items = list.reduce((acc, item, idx) => {
    const key = Math.floor(idx / perColumn)
    acc[key] = acc[key] || []
    acc[key].push(item)

    return acc
  }, [])
</script>

{@html Icon.spriteContent}

<WidgetHeader {title} {titleTag} {subTitle} withMargin={withTopMargin} class={cN('feedback')} />
<GridContainer class={cN('feedback')}>
  {#each items as itemsColumn}
    <GridColumn widthMiddle={4} class={cN('feedback__column')}>
      {#each itemsColumn as item, idx}
        <Item {...item} />
        <Separator key={15} size={Separator.SIZES.SMALL} />
      {/each}
    </GridColumn>
  {/each}
</GridContainer>
<WidgetFooter withMargin={withBottomMargin} />
