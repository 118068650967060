<script>
  import { Text, Icon, Separator } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import css from './style.scss'
  const cN = classNames.bind(css)

  export let clientName
  export let rank
  export let comment

  let isShort = comment.length > 130

  $: commentToShow = isShort ? `${comment.slice(0, 127)}...` : comment

  function showFull() {
    isShort = false
  }
</script>

<div class={cN('feedback__unit', { 'feedback__unit--short': isShort })} on:click={showFull}>
  <div class={cN('feedback__header')}>
    <Separator key={15} size={Separator.SIZES.SMALL} />
    <div class={cN('feedback__header-content')}>
      <Separator
        key={15}
        size={Separator.SIZES.SMALL}
        direction={Separator.DIRECTIONS.LEFT_RIGHT} />
      <Icon name={Icon.NAMES.star} key={Icon.KEYS.SMALL} class={cN('feedback__header-icon')} />
      <div class={cN('feedback__header-rating')}>{rank}</div>
      <Text size={Text.SIZES.STRONG}>{clientName}</Text>
      <Separator
        key={15}
        size={Separator.SIZES.SMALL}
        direction={Separator.DIRECTIONS.LEFT_RIGHT} />
    </div>
    <Separator key={15} size={Separator.SIZES.SMALL} />
  </div>
  <div class={cN('feedback__content')}>
    <Separator key={15} size={Separator.SIZES.SMALL} />
    <div class={cN('feedback__content-text')}>
      <Separator
        key={15}
        size={Separator.SIZES.SMALL}
        direction={Separator.DIRECTIONS.LEFT_RIGHT} />
      <Text>{commentToShow}</Text>
      <Separator
        key={15}
        size={Separator.SIZES.SMALL}
        direction={Separator.DIRECTIONS.LEFT_RIGHT} />
    </div>
    <Separator key={15} size={Separator.SIZES.SMALL} />
  </div>
  {#if isShort}
    <div class={cN('feedback__button')}>
      <Text>Раскрыть</Text>
      <Icon name={Icon.NAMES.arrow} key={Icon.KEYS.SMALL} />
    </div>
    <Separator key={15} size={Separator.SIZES.SMALL} />
  {/if}
</div>
